/* stylelint-disable no-descending-specificity */
input {
  width: 100%;
  height: 24px;
  padding-left: 4px;
  border: 1px solid palette(green70);
  border-radius: 2px;
  transition: all 250ms;

  &:hover:not(:disabled),
  &:focus {
    border: 1px solid palette(green60);
  }

  &:focus {
    outline: none;
  }
}
