h1,
h2,
h3 {
  margin: 0;
  font-weight: 700;
}

h2 {
  font-size: clamp-builder(64);
  line-height: 1.5; // 96px
}

b {
  font-weight: 700;
}

p {
  margin: 0;
}
