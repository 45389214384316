body {
  margin: 0;
  font-family: Poppins, sans-serif;
  font-weight: 500;
  color: palette(gray80);
}

a {
  transition: color 200ms;
}

img {
  max-width: 100%;
}

svg {
  display: block;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.wrap {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
