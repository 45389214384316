/* stylelint-disable scss/at-extend-no-missing-placeholder */
/* stylelint-disable no-descending-specificity */

.border-gold {
  position: relative;

  &::after {
    position: absolute;
    top: 0;
    right: clamp-builder(-32);
    bottom: 0;
    left: clamp-builder(-32);
    content: '';
    border: clamp-builder(6) solid palette(orange40);
    border-width: 0;
  }
}

th {
  box-sizing: border-box;
  font-size: clamp-builder(24);
  font-weight: 700;
  line-height: 1.42; // 34px
  text-align: center;

  &.column_divider {
    min-width: clamp-builder(57);
  }
}

tr {
  &.row_divider {
    height: clamp-builder(48);
  }
}

td {
  box-sizing: border-box;
  padding: clamp-builder(13) 0;
  font-size: clamp-builder(27);
  line-height: clamp-builder(42);

  &:first-child {
    padding-right: clamp-builder(15);
    padding-left: clamp-builder(7);
  }

  &:not(:first-child) {
    text-align: center;
  }

  &.gold {
    color: palette(orange80);
    background: palette(orange30);
  }

  &.dot {
    position: relative;

    &::before {
      position: absolute;
      top: 50%;
      left: 20%;
      width: clamp-builder(12);
      height: clamp-builder(12);
      content: '';
      background-color: palette(orange70);
      border-radius: 50%;
      transform: translateY(clamp-builder(-6));
    }
  }
}

table {
  width: 100%;
  color: palette(black);
  letter-spacing: -0.01em;
  border-collapse: collapse;

  // size
  &.sm {
    th {
      font-size: clamp-builder(16);
      line-height: 1.5; // 24px
    }

    td {
      padding-top: clamp-builder(9);
      padding-bottom: clamp-builder(9);
      font-size: clamp-builder(19);
      line-height: 1.37; // 26px

      &.dot {
        &::before {
          left: 15%;
          width: clamp-builder(8);
          height: clamp-builder(8);
          transform: translateY(clamp-builder(-4));
        }
      }
    }
  }

  &.md {
    th {
      font-size: clamp-builder(19);
      line-height: 1.37; // 26px
    }

    td {
      padding-top: 1px;
      padding-bottom: 1px;
      font-size: clamp-builder(21);
      line-height: 1.53;

      &.dot {
        &::before {
          left: 13%;
          width: clamp-builder(8);
          height: clamp-builder(8);
          transform: translateY(clamp-builder(-4));
        }
      }
    }
  }

  // slide
  &.cx-overview {
    th {
      &:first-child {
        width: 35%;
      }

      &:not(:first-child) {
        width: 21%;
        min-width: clamp-builder(250);
        padding: 0 clamp-builder(5) clamp-builder(4);
        border-bottom: 1px solid palette(gray60);
      }
    }

    // border-bottom from 4th row
    tbody {
      tr:nth-child(n + 4) {
        border-bottom: 1px solid palette(gray20);
      }
    }
  }

  &.cx-instore {
    @extend .cx-overview;

    th {
      &:first-child {
        width: 22%;
      }

      &:not(:first-child) {
        width: 19%;
        min-width: clamp-builder(344);
      }
    }

    tbody {
      tr:nth-child(2) {
        border-top: 1px solid palette(gray20);

        td {
          padding-top: clamp-builder(10);
          padding-bottom: clamp-builder(10);
        }
      }

      tr:nth-child(n + 4) {
        border-top: 1px solid palette(gray20);
        border-bottom: none;

        td {
          padding-top: 1px;
          padding-bottom: 1px;
        }
      }
    }

    td {
      &:first-child {
        padding-right: clamp-builder(112);
      }
    }
  }

  &.cx-drivers {
    @extend .cx-overview;

    th {
      letter-spacing: 0;

      &:first-child {
        width: 12%;
        min-width: clamp-builder(200);
      }

      &:not(:first-child) {
        width: 6%;
        min-width: clamp-builder(100);
        padding: 0 clamp-builder(7) clamp-builder(4);
      }
    }

    tbody {
      tr {
        &:nth-child(2) {
          border-top: 1px solid palette(gray20);
        }

        &.row_divider {
          height: clamp-builder(40);
        }
      }

      td {
        padding-top: clamp-builder(9);
        padding-bottom: clamp-builder(9);
      }
    }
  }

  &.cx-delivery-satisfaction {
    @extend .cx-overview;

    td {
      padding-top: clamp-builder(4);
      padding-bottom: clamp-builder(4);
      font-size: clamp-builder(24);
      line-height: 1.5;
    }
  }

  &.cx-delivery {
    @extend .cx-delivery-satisfaction;

    thead {
      @extend .border-gold;

      &::after {
        top: clamp-builder(-32);
        border-width: clamp-builder(6) clamp-builder(6) 0;
      }
    }

    th {
      &:first-child {
        width: 19%;
        min-width: clamp-builder(300);
      }

      &:not(:first-child) {
        width: 13%;
        min-width: clamp-builder(200);
      }
    }

    tbody {
      tr {
        &:first-child,
        &:nth-child(2) {
          td {
            @extend .border-gold;

            &:first-child {
              &::after {
                border-left-width: clamp-builder(6);
              }
            }

            &:last-child {
              &::after {
                border-right-width: clamp-builder(6);
              }
            }
          }
        }

        &:nth-child(2) {
          box-shadow: 0 -1px palette(gray20);

          td {
            &::after {
              bottom: clamp-builder(-32);
              border-bottom-width: clamp-builder(6);
            }
          }
        }
      }
    }

    td {
      &:first-child {
        padding-right: clamp-builder(90);
      }
    }

    &.row_divider {
      height: clamp-builder(80);
    }
  }

  &.cx-attributes {
    th {
      &:first-child {
        width: 34%;
      }

      &:nth-child(2),
      &:nth-child(3) {
        width: 10%;
      }

      &:nth-child(n + 5) {
        width: 8%;
        min-width: clamp-builder(100);
      }

      &:nth-child(n + 2):not(.column_divider) {
        padding: 0 clamp-builder(5) clamp-builder(4);
        border-bottom: clamp-builder(4) solid palette(black);
      }
    }

    // content cells borders
    tbody {
      tr:nth-child(n + 2) {
        td:not(:nth-child(n + 4)) {
          border-top: 1px solid palette(gray20);
        }
      }

      td {
        &:nth-child(n + 5) {
          border-right: 1px solid palette(white);
          border-bottom: 1px solid palette(white);
        }
      }
    }
  }

  &.cx-retail-overview {
    @extend .cx-overview;

    th {
      &:first-child {
        width: 22%;
      }

      &:not(:first-child) {
        width: 19%;
        padding-right: clamp-builder(60);
        padding-left: clamp-builder(60);
      }
    }
  }

  &.cx-non-buyer-analysis {
    @extend .cx-attributes;

    td {
      padding-top: clamp-builder(14);
      padding-bottom: clamp-builder(14);
    }
  }
}
