/* stylelint-disable no-descending-specificity */
button {
  padding: 0;
  cursor: pointer;
  background: none;
  border: 0;
  outline: none;
  appearance: none;
}

.btn {
  &.loading {
    cursor: progress;
  }

  &-green60 {
    min-width: 117px;
    padding: 6px 12px;
    color: palette(white);
    background-color: palette(green70);
    border-radius: 2px;
    transition: all 250ms;
  }

  &:hover:not(:disabled),
  &:focus {
    background-color: palette(green60);
  }

  &:disabled {
    opacity: 0.85;
  }
}
